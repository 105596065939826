import React from "react";
import Styles from "./Header.module.css";

const Header = () => {
  return (
    <header className={Styles.header} id={"home"}>
      {/* NAVIGATION */}
      {/* <Nav /> */}
      {/* SHOWCASE */}
    </header>
  );
};

export default Header;
